import React, { useState, useEffect } from 'react';

import { Main, TextHome, TextHomeWrapper } from './styles';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { trigger } from '../../storage/redux/about';
import Logo1 from '../../assets/logo/logo_1.svg';
import Logo2 from '../../assets/logo/logo_2.svg';

function Home() {
  const [hotSpot, setHotSpot] = useState(' ');
  const dispatch = useDispatch();
  const { about } = useSelector((state) => state);

  useEffect(() => {
    dispatch(trigger());
  }, [dispatch]);

  const toggleHover = (className) => {
    setHotSpot(className);
  };

  return (
    <Main
      onMouseEnter={() => toggleHover('hotSpot')}
      onMouseLeave={() => toggleHover('')}
      className={hotSpot}
    >
      <Link
        to="/work/gaggiorisanamentorustico"
        onMouseEnter={() => toggleHover('hotSpot')}
        onMouseLeave={() => toggleHover(' ')}
      >
        <div className="logo">
          <Image src={Logo2} style={{ width: 78, height: 'auto' }} />
          <Image src={Logo1} style={{ width: 78, height: 'auto' }} />
        </div>
      </Link>

      <Container style={{ paddingLeft: '0px' }} fluid>
        <TextHomeWrapper>
          <TextHome>{about.contact.name}</TextHome>
          <div>
            <TextHome className="noPaddinTop">{about.contact.phone}</TextHome>
            <a href={`mailto:${about.contact.email}`}>
              <TextHome
                onMouseEnter={() => toggleHover('')}
                onMouseLeave={() => toggleHover('hotSpot')}
                noPadding
              >
                email
              </TextHome>
            </a>
          </div>
        </TextHomeWrapper>
      </Container>
    </Main>
  );
}

export default Home;
