import styled from 'styled-components';

export const MainTitle = styled.h2`
  font-size: 2.5em;
  width: 21em;
  color: #ffffff;
  font-weight: normal;
  @media screen and (max-width: 1100px) {
    width: 90%;
    font-size: 1.5em;
    padding-left: 15px;
    padding-right: 30px;
    top: 25% !important;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
    font-size: 1.5em;
    padding-left: 15px;
    padding-right: 30px;
    top: 30% !important;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
    font-size: 1.5em;
    top: 25% !important;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const Fixed = styled.div`
  position: fixed;
  top: 18%;
`;

export const Left = styled.div`
  width: 40%;
  z-index: 200;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
`;

export const Right = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60%;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Title = styled.h3`
  width: 100%;
  margin: 0px;
  margin-bottom: 2px;
  font-size: 1.25rem;
  font-weight: 400;
  color: #000;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
  &:hover {
    color: #776e62;
    transition: 400ms;
  }
`;

export const Text = styled.p`
  margin: 0px;
  font-size: 1rem;
  color: #776e62;
  cursor: pointer;
`;

// export const FilterText = styled.p`
//   margin: 0px;
//   font-size: 1rem;
//   color: #776e62;
//   cursor: pointer;
// `;

export const FilterText = styled.button`
  background: none;
  color: #776e62;
  font-family: 'Myriad', Sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  border: none;
  margin: 0;
  margin-bottom: 2px;
  padding: 0;
  text-align: left;
  cursor: pointer;
  /* line-height: 0px; */
  transition: all 0.6s ease-in-out;
  font-weight: 400;
  &:hover {
    color: #000 !important;
    transition: 400ms;
  }
  &:focus {
    outline: none !important;
  }
`;

export const Button = styled.button`
  background: none;
  color: ${(props) => (props.isAtive ? '#fff' : '#5a5a5a')};
  font-family: 'Myriad', Sans-serif;
  transition: all 0.6s ease-in-out;
  font-size: 20px;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  border: none;
  margin: 0;
  margin-bottom: 0 !important;
  padding: 0;
  text-align: left;
  position: relative;
  z-index: 301;
  cursor: pointer;
  line-height: 1.2em;
  font-weight: 400;
  &:hover {
    color: #fff !important;
    transition: all 0.6s ease-in-out;
  }
  &:focus {
    outline: none !important;
  }
`;

export const ButtonClose = styled.button`
  position: fixed;
  z-index: 200;
  top: 18px;
  left: 20px;
  background: none;
  color: #5a5a5a;
  font-family: 'Myriad', Sans-serif;
  font-size: 1.25rem;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  @media (max-width: 500px) {
    position: absolute;
  }
  &:hover {
    color: #fff !important;
  }
  &:focus {
    outline: none !important;
  }
`;
