import styled from "styled-components";

export const Text = styled.p`
  font-size: 20px;
  font-family: "Myriad", Sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  margin: 0px;
  color: #776e62;
`;
export const Title = styled.h2`
  font-size: 20px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
`;
export const Fade = styled.div`
  opacity: ${(props) => (props.in ? 1 : 0)};
  transition: opacity 400ms;
  position: absolute;
  @media screen and (max-width: 500px) {
    position: relative;
    display: ${(props) => (props.in ? "block" : "none")};
  }
`;
