import styled from "styled-components";

export const Fixed = styled.div`
  position: fixed;
  top: 18%;
`;

export const Left = styled.div`
  height: 100%;
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  width: 40%;
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1120px) {
    width: 100%;
    padding-bottom: 200%;
  }
`;

export const Right = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60%;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Title = styled.h3`
  margin: 0px;
  font-size: ;
  font-weight: 400;
  color: #000;
  cursor: pointer;
`;

export const Text = styled.p`
  margin: 0px;
  font-size: 1rem;
  color: #776e62;
  cursor: pointer;
`;

// export const FilterText = styled.p`
//   margin: 0px;
//   font-size: 1rem;
//   color: #776e62;
//   cursor: pointer;
// `;

export const FilterText = styled.button`
  background: none;
  color: #776e62;
  font-family: "Myriad", Sans-serif;
  font-size: 1.18rem;
  line-height: 1.5em;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
  cursor: pointer;

  /* line-height: 0px; */
  font-weight: 400;

  &:hover {
    color: #000 !important;
  }

  &:focus {
    outline: none !important;
  }
`;

export const Button = styled.button`
  background: none;
  color: ${(props) => (props.isAtive ? "#fff" : "#5a5a5a")};
  font-family: "Myriad", Sans-serif;
  font-size: 1.18rem;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
  position: relative;
  z-index: 2;
  cursor: pointer;

  /* line-height: 0px; */
  font-weight: 400;

  &:hover {
    color: #fff !important;
  }

  &:focus {
    outline: none !important;
  }
`;

export const ButtonClose = styled.button`
  position: fixed;
  z-index: 200;
  top: 18px;
  left: 20px;
  background: none;
  color: #5a5a5a;
  font-family: "Myriad", Sans-serif;
  font-size: 20px;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: #fff !important;
  }

  &:focus {
    outline: none !important;
  }
`;
