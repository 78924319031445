import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { ImageResponsive } from "./styles";

const GalleryImage = (props) => {
  const { label, image, label_position, orientation, id } = props;
  const [browser, setBrowser] = React.useState("");

  useEffect(() => {
    checkBrowser();
    window.scrollTo(0, 0);
  }, []);
  const checkBrowser = () => {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;
    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      setBrowser("Safari");
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
  };
  const _renderMaxWidth = (resolution = "") => {
    if (resolution === "HD") {
      switch (orientation) {
        case "landscape":
          return "1400";
        case "portrait":
          return "500";
        case "plan":
          return "2400";
        default:
          return "none";
      }
    }
    switch (orientation) {
      case "landscape":
        return "1150";
      case "portrait":
        return "500";
      case "plan":
        return "1200";
      default:
        return "none";
    }
  };
  return (
    <Col
      className={`d-flex align-items-center overMenu ${
        browser !== "Safari" ? "child" : "childSafari"
      }`}
      lg="20"
    >
      <div
        id={id}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div style={{ width: window.innerWidth <= 600 ? "100%" : "" }}>
          {label_position === "top" && (
            <span
              style={{
                fontSize: "0.8rem",
                color: "#776E62",
                letterSpacing: "0.02em",
                zIndex: 1,
                left: window.innerWidth >= 750 ? "0rem" : "2.8rem",
                paddingLeft: window.innerWidth >= 750 ? "40px" : "0px",
                position: window.innerWidth >= 750 ? "relative" : "absolute",
                top: window.innerWidth >= 750 ? "0px" : "5px",
              }}
            >
              {label}
            </span>
          )}
          <ImageResponsive
            src={image}
            orientation={orientation}
            maxWidth={_renderMaxWidth()}
            maxWidthHD={_renderMaxWidth("HD")}
          />
          {label_position === "bottom" && (
            <span
              style={{
                fontSize: "0.8rem",
                letterSpacing: "0.02em",
                color: "#776E62",
                zIndex: 1,
                left: window.innerWidth >= 750 ? "0rem" : "2.8rem",
                bottom: window.innerWidth >= 750 ? "0px" : "5px",
                paddingLeft: window.innerWidth >= 750 ? "40px" : "0px",
                position: window.innerWidth >= 750 ? "relative" : "absolute",
              }}
            >
              {label}
            </span>
          )}
        </div>
      </div>
    </Col>
  );
};

export default GalleryImage;
