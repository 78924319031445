import React, { useEffect, useState, useRef } from 'react';
// REACT ROUTER DOM
import { Link } from 'react-router-dom';
// REDUX
import { useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';

// STYLED COMPONENTS
import {
  Container,
  Container2,
  Text,
  ButtonClose,
  BG,
  GalleryName,
  BGabout
} from './styles';
// STYLES
import './style.css';

function Menu(props) {
  const { project } = useSelector((state) => state.projects);
  const [openFilter, setOpenFilter] = useState(false);
  const myRef = useRef(<div />);

  useEffect(() => {
    if (!openFilter) {
      //Scroll to top
      window.scrollTo(0, 0); // Keep an eye on this one
      //fade logo
      let el = document.querySelector('.fadeLogo');
      el.classList.add('fadeIn');
    }
  }, [openFilter]);

  if (props.page === 'about') {
    return (
      <>
        <div className="about-centering">
          <ButtonClose onClick={() => props.goBack()}>
            <Close
              style={{
                position: 'fixed',
                color: '#5a5a5a',
                top: '20px',
                left: '16px',
                width: '18px',
                cursor: 'pointer',
                zIndex: 332
              }}
            />
          </ButtonClose>
        </div>

        <Container close className="w-md-100 fadeLogo decoration">
          {window.location.pathname !== '/about' ? (
            <Link to="/about">
              <Text black>Piero Conconi Architetto</Text>
            </Link>
          ) : !props.hideNavbar ? (
            <Text className="gray-text-mobile" black>
              Piero Conconi Architetto
            </Text>
          ) : null}
        </Container>
        {!props.hideNavbar && (
          <>
            {' '}
            <BGabout id="bg-about" />
            <BGabout id="bg-about" />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="background-nav-menu">
        <Container id="HELLO" className="w-md-100 fadeLogo decoration">
          <Link
            onClick={() => {
              setOpenFilter(!openFilter);
            }}
            to="/about"
          >
            <Text className="marginLeftMobile">Piero Conconi Architetto</Text>
          </Link>
          <GalleryName
            onClick={props.onWorkPress}
            black
            style={{ maxWidth: '300px' }}
            className="marginLeftMobile"
          >
            {project.name}
          </GalleryName>
          {props.renderArrow && (
            <Container2>
              <Link to={props.arrowLink} style={{ zIndex: 444 }}>
                <p className="prossimo m-0">→</p>
              </Link>
            </Container2>
          )}
        </Container>
        <BG />
      </div>
    </>
  );
}

export default Menu;
