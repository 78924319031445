import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
`;

export const TextHomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 18.9px;
  padding-left: 45px;

  @media (min-width: 875px) {
    flex-direction: row;
  }
`;

export const TextHome = styled.p`
  font-family: 'Myriad', Sans-serif;
  line-height: 1.3em;
  margin: 0px;
  font-size: 20px;
  position: relative;
  text-decoration: none;
  z-index: 333;
  color: ${(props) => (props.black ? '#000' : '#776e62')};
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;

  @media (min-width: 875px) {
    min-width: 430px;
  }
`;
