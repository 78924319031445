import React from "react";
import { Container, Row } from "react-bootstrap";
import { Text } from "./styles";

export default function Collaboratori({ about }) {
  var names = about.collabs.map((collab) => {
    return collab.name;
  });
  return (
    <Container fluid className=" w-md-100 pb-4">
      <Row style={{ marginBottom: "1rem" }}>
        <div className="hack col-mattia"> </div>
        <div className="col-about-tab">
          {names.map((name) => (
            <Text style={{lineHeight:"25px"}} key={"name " + name}>{name}</Text>
          ))}
        </div>
      </Row>
    </Container>
  );
}
