import { call, put, all, takeLatest } from 'redux-saga/effects';
import { Types, request, success, failed, fulfill } from '../ducks';
import { getAbout } from '../../../../services';

function* loadContact() {
  yield put(request());
  try {
    const { data } = yield call(getAbout);
    if (data) {
      yield put(success(data.about));
    }
  } catch (error) {
    yield put(failed(error.message));
  } finally {
    yield put(fulfill());
  }
}

export default function* aboutSaga() {
  yield all([takeLatest(Types.TRIGGER, loadContact)]);
}
