import React from 'react';
import { Container } from './styled';
import "./style.css"


const HtmlRender = (props) => {
  const { children } = props;

  const createContent = () => {
    if (children) {
      return { __html: children };
    }
  };


  return <Container className="color" dangerouslySetInnerHTML= {createContent ()} />;


};



export default HtmlRender;
