import Direzionelavori from "./Direzionelavori/01.jpg";
import concorsoReiffeisen from "./concorsoReiffeisen/01.jpg";
import CantinaKopp from "./CantinaKopp/01.jpg";
import CarducciGiosue from "./CarducciGiosue/01.jpg";

const Data = [
  {
    id: "1",
    name: "Ponsectetur adipiscing elit",
    img: "",
    page: "Ponsectetur",
  },

  {
    id: "2",
    name: "Sullam non tristique",
    img: "",
    page: "Sullam",
  },
  {
    id: "3",
    name: "Sed ac sagittis augue",
    img: "",
    page: "sagittis",
  },
  {
    id: "4",
    name: "Urabitur malesuada",
    img: "",
    page: "Urabitur",
  },
  {
    id: "5",
    name: "Zitricies neque venenatis",
    img: "",
    page: "Zitricies",
  },
  {
    id: "6",
    name: "Borghini Lugano",
    img: "",
    page: "Borghini",
  },
  {
    id: "7",
    name: "Casa Mueller Muzzano",
    img: "",
    page: "Mueller",
  },
  {
    id: "8",
    name: "Galleria Lugano ",
    img: "",
    page: "Lugano",
  },
  {
    id: "9",
    name: "Museo Cantonale Lugano",
    img: "",
    page: "Cantonale",
  },
  {
    id: "10",
    name: "Norem ipsum dolor ",
    img: "",
    page: "Norem",
  },
  {
    id: "11",
    name: "Ponsectetur adipiscing elit",
    img: "",
    page: "adipiscing",
  },
  {
    id: "12",
    name: "Sullam non tristique",
    img: "",
    page: "Sullam",
  },
  {
    id: "13",
    name: "Sed ac sagittis augue ",
    img: "",
    page: "sagittisaugue",
  },
  {
    id: "14",
    name: "Galleria, Via Giosuè Carducci, Lugano",
    img: CarducciGiosue,
    page: "CarducciGiosue",
  },
  {
    id: "15",
    name: "Concorso Banca Reiffeisen, Colline del Ceresio",
    img: concorsoReiffeisen,
    page: "concorsoReiffeisen",
  },
  {
    id: "16",
    name: "Direzione lavori, Parco Nocc, Gentilino",
    img: Direzionelavori,
    page: "Direzionelavori",
  },
  {
    id: "17",
    name: "Studio di fattibilità, Casa della Musica, Besso",
    img: "",
    page: "StudioMusica",
  },
  {
    id: "18",
    name: "Cantina Kopp von der Crone, Barbengo",
    img: CantinaKopp,
    page: "CantinaKopp",
  },
  {
    id: "19",
    name: "Sullam non tristique",
    img: "",
    page: "Sullamtristique",
  },
  {
    id: "20",
    name: "Sed ac sagittis augue",
    img: "",
    page: "auguesagittis",
  },
  {
    id: "21",
    name: "Urabitur malesuada",
    img: "",
    page: "urabiturmalesuada",
  },
  {
    id: "22",
    name: "Zitricies neque venenatis",
    img: "",
    page: "Zitriciesvenenatis",
  },
  {
    id: "23",
    name: "Borghini Lugano",
    img: "",
    page: "LuganoBorghini",
  },
  {
    id: "24",
    name: "Casa Mueller Muzzano",
    img: "",
    page: "MuzzanoMueller",
  },
  {
    id: "25",
    name: "Galleria Lugano",
    img: "",
    page: "LuganoGalleria",
  },
  {
    id: "26",
    name: "Museo Cantonale Lugano",
    img: "",
    page: "MuseoCantonale",
  },
  {
    id: "27",
    name: "Norem ipsum dolor",
    img: "",
    page: "ipsumdolor",
  },
  {
    id: "28",
    name: "Ponsectetur adipiscing elit",
    img: "",
    page: "elitadipiscing",
  },
  {
    id: "29",
    name: "Sullam non tristique",
    img: "",
    page: "tristiqueSullam",
  },
  {
    id: "30",
    name: "Sed ac sagittis augue",
    img: "",
    page: "augueSed",
  },
  {
    id: "31",
    name: "Urabitur malesuada",
    img: "",
    page: "malesuadaUrabitur",
  },
  {
    id: "32",
    name: "Zitricies neque venenatis",
    img: "",
    page: "Zitriciesneque",
  },
  {
    id: "33",
    name: "Rabitur malesuada",
    img: "",
    page: "Rabiturmalesuada",
  },
];

export default Data;
