import { call, put, all, takeLatest } from "redux-saga/effects";
import { Types, request, success, failed, fulfill } from "../ducks";
import { getBios } from "../../../../services";

function* loadBios() {
  yield put(request());
  try {
    const { data } = yield call(getBios);
    if (data) {
      yield put(success(data));
    }
  } catch (error) {
    yield put(failed(error.message));
  } finally {
    yield put(fulfill());
  }
}

export default function* biosSaga() {
  yield all([takeLatest(Types.TRIGGER, loadBios)]);
}
