import styled from 'styled-components';

export const Text = styled.p`
  font-size: 20px;
  z-index: 331;
  color: #776e62;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  margin-bottom: 0px;
  paddind-bottom: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @media (min-width: 1290px) {
    margin-left: ${(props) => props.extraMargin && '2.8rem'};
  }
`;

export const Title = styled.h2`
  font-size: 2.5em;
  width: 21em; 
  z-index:333,
  color: #776e62;
  font-weight: normal;
  @media screen and (max-width: 1100px) {
    width: 90%;
    font-size: 1.5em;
    padding-left: 15px;
    padding-right: 30px;
    top: 25% !important;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
    font-size: 1.5em;
    padding-left: 15px;
    padding-right: 30px; 
    top: 40% !important;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
    font-size: 2em;
    top: 50% !important;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const ImageResponsive = styled.img`
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  align-self: center;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 600px) {
    z-index: 332;
    max-height: ${(props) =>
      props.orientation === 'landscape'
        ? '64vh'
        : props.orientation === 'portrait'
        ? '59vh'
        : '64vh'};
    width: 100% !important;
    object-fit: cover;
  }
  @media screen and (min-width: 600px) {
    max-width: ${(props) =>
      props.orientation === 'landscape'
        ? '50vw'
        : props.orientation === 'portrait'
        ? '45.5vw'
        : '80.5vw'};
    max-height: 75vh;
    z-index: 332;
  }
`;

export const ImageSpacing = styled.div`
  height: ${(props) =>
    props.orientation === 'landscape'
      ? '200px'
      : props.orientation === 'portrait'
      ? '220px'
      : '185px'};

  @media screen and (max-width: 601px) {
    height: ${(props) =>
      props.orientation === 'landscape'
        ? '120px'
        : props.orientation === 'portrait'
        ? '132px'
        : '111px'};
  }
`;
