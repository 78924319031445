import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  ButtonClose,
  Title,
  Fixed,
  Left,
  Right,
  FilterText,
} from "./styles";
import { Container, Row, Col, Image } from "react-bootstrap";
import Img1 from "../../data/files/1.jpg";
import DATA from "../../data/Data";

// General scroll to element function
const scrollToRef = (ref, leftRef) => {
  if (window.innerWidth < 700) {
    leftRef.current.scroll(0, ref.current.offsetTop - 20);
    return;
  }
  window.scrollTo(0, ref.current.offsetTop + 12);
};

function Filter(props) {
  const [data, setData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [locationData, setlocationData] = useState(props.location.state);
  const [img, setImg] = useState();
  const [fade, setFade] = useState(false);
  const myRef = useRef(<div />);
  const leftRef = useRef(<div />);

  const executeScroll = () => {
    scrollToRef(myRef, leftRef);
  };

  useEffect(() => {
    handleSubmitAPI();
    setTimeout(function () {
      setFade(false);
    }, 600);
    document.body.classList.add("bg-black");

    //fade Page
    let el = document.querySelector(".fadePage");
    el.classList.add("fadeIn");

    window.addEventListener("mousewheel", detectMouseWheelDirection);

    return () => {
      document.body.classList.remove("bg-black");
      window.removeEventListener("mousewheel", detectMouseWheelDirection);
    };
  }, []);

  useEffect(() => {
    executeScroll();
  }, [data]);

  const handleSubmitAPI = () => {
    setData(DATA);
  };

  const goto = (page) => {
    setFade(true);
    setTimeout(function () {
      props.history.push("/work/" + page);
    }, 600);
  };

  const renderButtons = () => {
    let buttons = data.map((value, key) => {
      return (
        <div key={key} ref={value.page === locationData.page ? myRef : null}>
          <Button
            className="line-h"
            onClick={() => goto(value.page)}
            isAtive={value.page === locationData.page ? true : false}
            onMouseEnter={() => setImg(Img1)}
            onMouseLeave={() => setImg("")}
          >
            {value.name.length > 30 && window.innerWidth < 700
              ? value.name.substring(0, 30) + "..."
              : value.name}
          </Button>
        </div>
      );
    });

    return buttons;
  };

  const detectMouseWheelDirection = (e) => {
    var delta = null,
      direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? "up" : "down";
    }
    return direction;
  };

  const renderFilters = () => {
    if (openFilter) {
      return (
        <>
          <Col
            className="p-filter bg-light d-flex align-items-center pt-0 pb-0"
            lg="12"
            style={{ position: "relative", zIndex: 199 }}
          >
            <FilterText>Industrial</FilterText>
          </Col>

          <Col
            className="p-filter bg-light d-flex align-items-center pt-0 pb-0"
            lg="12"
            style={{ position: "relative", zIndex: 199 }}
          >
            <FilterText>Commercial</FilterText>
          </Col>

          <Col
            className="p-filter bg-light d-flex align-items-center pt-0 pb-0"
            lg="12"
            style={{ position: "relative", zIndex: 199 }}
          >
            <FilterText>Residential</FilterText>
          </Col>
        </>
      );
    }
  };

  return (
    <Container className={(fade ? "fadeOut" : "", "fadePage")} fluid>
      <ButtonClose onClick={() => props.history.goBack()}>
        <div className="nav-icon-progetti open-progetti">
          <span></span>
          <span></span>
        </div>
      </ButtonClose>
      <Row>
        <Col
          className="p-filter bg-light d-flex align-items-center"
          lg="12"
          style={{ position: "relative", zIndex: 199 }}
        >
          <Title onClick={() => setOpenFilter((old) => !old)}>Filtrare</Title>
        </Col>
        {renderFilters()}
        <Left
          ref={leftRef}
          className="p-filter"
          style={{
            marginTop: 10,
            overflowY: "scroll",
            paddingBottom: "100%",
          }}
        >
          {renderButtons()}
        </Left>
        <Right>
          <Fixed>
            <Image className="bw preview" src={img} />
          </Fixed>
        </Right>
      </Row>
    </Container>
  );
}

export default Filter;
