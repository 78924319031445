import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  trigger as triggerProjects,
  triggerProject
} from '../../storage/redux/projects';
import { useParams } from 'react-router-dom';

import Menu from '../../components/Menu/index';
import FilterMenu from '../filterMenu/index';
import { Text, Title, ImageSpacing } from './styles';
import GalleryImage from './GalleryImage';
import HtmlRender from '../../components/HtmlRender';

function Works({ match, history }) {
  // STATE HOOKS
  const [name, setName] = useState('');
  const [arrow, setArrow] = useState(false);
  const [browser, setBrowser] = useState('');
  const [nextLink, setNextLink] = useState('');
  const [haveNext, setHaveNext] = useState(false);
  const [opacityIMG, setOpacityIMG] = useState(1);
  const [noScroll, setNoScroll] = useState(false);
  const [opacityText, setOpacityText] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // REDUX HOOKS
  const dispatch = useDispatch();
  var { project, projects, loading } = useSelector((state) => state.projects);

  // REACT ROUTER HOOKS
  const { codename } = useParams();

  const myRef = useRef(<div />);

  useEffect(() => {
    setOpacityIMG(1);
    const projectsCodename = projects.map((proj) => proj.codename);
    const projectIndex = projectsCodename.indexOf(project.codename);
    if (projectIndex < projects.length - 1) {
      const nextCodename = projects[projectIndex + 1].codename;
      setNextLink(nextCodename);
      setHaveNext(true);
    } else {
      setHaveNext(false);
      setNextLink('');
    }
    if (project.main_image !== null) {
      getMeta(project.main_image.url, function (width, height) {
        setDimensions({ height: height, width: width });
      });
    }
  }, [projects, project]);

  function getMeta(url, callback) {
    var img = new Image();
    img.src = url;
    img.onload = function () {
      callback(this.width, this.height);
    };
  }

  useEffect(() => {
    dispatch(triggerProject(codename));
  }, [codename, dispatch]);

  useEffect(() => {
    dispatch(triggerProjects());
  }, [dispatch]);

  const detectMouseWheelDirection = (e) => {
    var delta = null,
      direction = false;
    if (window.innerWidth <= 600) {
      return;
    } else {
      if (!e) {
        // if the event is not provided, we get it from the window object
        e = window.event;
      }
      if (e.wheelDelta) {
        // will work in most cases and Safari as per documentation
        delta = e.wheelDelta / 60;
        // fallback as per Safari as per documentation
      } else if (e.deltaMode) {
        // fallback as per Safari 9.0 as per documentation
        delta = e.deltaMode / 2;
      } else if (e.wheelDeltaY) {
        // fallback as per Safari as per documentation
        delta = e.wheelDeltaY / 2;
      } else if (e.detail) {
        // fallback for Opera, Firefox and MacOs (except Safari)
        delta = -e.detail / 2;
      }
      if (delta !== null) {
        direction = delta > 0 ? 'up' : 'down';
      }

      if (direction === 'down') {
        if (opacityIMG !== 0) {
          setOpacityIMG(0);
          setOpacityText(0);
        }
      } else {
        setOpacityIMG(1);
        setOpacityText(1);
      }

      return direction;
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    window.addEventListener('mousewheel', detectMouseWheelDirection);
    //Scroll to top

    //fade logo
    let el = document.querySelector('.fadeLogo');
    el.classList.add('fadeIn');
    // return () => {
    //   window.removeEventListener('scroll', trackScrolling);
    //   window.removeEventListener('mousewheel', detectMouseWheelDirection);
    // };
  }, []);

  useEffect(() => {
    if (!openFilter) {
      //Scroll to top
      //window.scrollTo(0, myRef.current.offsetTop + 15);

      //fade logo
      let el = document.querySelector('.fadeLogo');
      el.classList.add('fadeIn');
    }
  }, [openFilter]);

  const trackScrolling = () => {
    const el = document.documentElement;
    const main = document.getElementById('main');
    const first = document.getElementById('gallery-0');
    const topValue = el.getBoundingClientRect().top * -1;
    const scrollHeight = document.body.scrollHeight;
    const calculo = scrollHeight / 2;

    if (window.innerWidth <= 600) {
      //detected mobile device!
      if (first) {
        if (
          first.getBoundingClientRect().top -
            main.getBoundingClientRect().bottom <=
          100
        ) {
          setOpacityIMG(0);
          setOpacityText(0);
        } else {
          setOpacityIMG(1);
          setOpacityText(1);
        }
      }
      /*
      if (window.scrollY <= 100) {
        setOpacityIMG(1);
        setOpacityText(1);
      } else {
        setOpacityIMG(0);
        setOpacityText(0);
      }
      */
    }
    if (topValue >= calculo + 100) {
      setArrow(true);
    } else {
      setArrow(false);
    }
  };

  useEffect(() => {
    checkBrowser();
    window.scrollTo({
      top: 0
    });
  }, []);

  const checkBrowser = () => {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;
    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browserName = 'Safari';
      setBrowser(browserName);
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(' ')) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    if (browserName === 'Safari' && window.innerWidth <= 650) {
      setNoScroll(true);
    }
    if (window.innerWidth < 1024) {
      setNoScroll(true);
    }
  };

  // You can most certainly refine this.
  const renderMainImage = () => {
    if (project.show_title) {
      return (
        <Title
          id="main"
          style={{
            maxHeight: '75%',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            transition: 'opacity .25s ease-in-out',
            opacity: opacityText
          }}
        >
          {project.title}
        </Title>
      );
    } else {
      if (dimensions.height > dimensions.width) {
        // If the height is greater than width, the image is in portrait mode
        if (noScroll === true) {
          // This gets rendered on mobile
          return (
            <>
              {!loading ? (
                <img
                  id="main"
                  className="img-first-portrait" // This class and img-first-landscape both have the SAME RULES
                  src={project.main_image.url}
                  style={{
                    marginTop: '14px',
                    width: window.innerWidth <= 600 ? '100vw' : '',
                    position: 'fixed',
                    top: '50%',
                    // left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 332,
                    opacity: window.innerWidth > 750 ? opacityIMG : 1,
                    transition:
                      window.innerWidth > 750 ? 'opacity .25s ease-in-out' : ''
                  }}
                />
              ) : (
                ''
              )}
            </>
          );
        } else {
          // This gets rendered on desktop
          return (
            <>
              {!loading ? (
                <img
                  id="main"
                  className="img-first-portrait"
                  src={project.main_image.url}
                  style={{
                    marginTop: '14px',
                    width: window.innerWidth <= 600 ? '100vw' : '',
                    position: 'fixed',
                    top: '50%',
                    // left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 332,
                    opacity: window.innerWidth > 750 ? opacityIMG : 1,
                    transition:
                      window.innerWidth > 750 ? 'opacity .25s ease-in-out' : ''
                  }}
                />
              ) : (
                ''
              )}
            </>
          );
        }
      } else {
        if (noScroll === true) {
          // If the width is greater than height, the image is in landscape mode
          // This gets rendered on mobile
          return (
            <div>
              {!loading ? (
                <img
                  id="main"
                  className="img-first-landscape"
                  src={project.main_image.url}
                  style={{
                    position: 'fixed',
                    top: '50%',
                    // left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 332,
                    opacity: window.innerWidth > 750 ? opacityIMG : 1,
                    transition:
                      window.innerWidth > 750 ? 'opacity .25s ease-in-out' : ''
                  }}
                />
              ) : (
                ''
              )}
            </div>
          );
        } else {
          // This gets rendered on desktop
          return (
            <div>
              {!loading ? (
                <img
                  id="main"
                  className="img-first-landscape"
                  src={project.main_image.url}
                  style={{
                    position: 'fixed',
                    top: '50%',
                    // left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 332,
                    opacity: window.innerWidth > 750 ? opacityIMG : 1,
                    transition:
                      window.innerWidth > 750 ? 'opacity .25s ease-in-out' : ''
                  }}
                />
              ) : (
                ''
              )}
            </div>
          );
        }
      }
    }
  };

  const renderImages = () => {
    return project.gallery.map((item, index) => {
      const { image, label, label_position, orientation } = item;
      return (
        <>
          <GalleryImage
            id={`gallery-${index}`}
            key={image.url}
            label={label}
            label_position={label_position}
            image={image.url}
            orientation={orientation}
          />
          <ImageSpacing
            orientation={
              index === project.gallery.length - 1
                ? orientation
                : project.gallery[index + 1].orientation
            }
            isLast={index === project.gallery.length - 1 ? true : false}
          />
        </>
      );
    });
  };

  return (
    <div
      style={{
        overflow: browser === 'Safari' ? 'auto' : ''
      }}
    >
      {openFilter && (
        <FilterMenu
          history={history}
          page={match.params.name}
          changePage={(name) => setName(name)}
          active={openFilter}
          close={() => setOpenFilter(false)}
        />
      )}

      <div
        classname="work-wrapper"
        style={{ display: openFilter ? 'none' : 'block' }}
      >
        <Menu
          onWorkPress={() => {
            setOpacityIMG(1);
            setOpenFilter(true);
          }}
          page={match.params.name}
          opacity={opacityIMG}
          renderArrow={arrow && haveNext}
          title={name}
          arrowLink={haveNext ? `/work/${nextLink}` : ''}
        />
        <Container className="d-flex justify-content-center">
          <Row
            ref={myRef}
            className="w-65 w-md-100  d-flex justify-content-center"
          >
            <Col
              className="vh-100 vh-100-main d-flex align-items-center overMenu"
              lg="14"
            >
              {window.innerWidth <= 600 ? (
                <div
                  data-aos="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                  data-aos-mirror="true"
                  className="w-65"
                  style={{ opacity: opacityIMG }}
                >
                  {renderMainImage()}
                </div>
              ) : (
                <div
                  data-aos="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                  data-aos-mirror="true"
                  data-aos-offset="300"
                  className="w-65"
                >
                  {renderMainImage()}
                </div>
              )}
            </Col>
            {renderImages()}
          </Row>
        </Container>
        <Container fluid className="mb-5 pb-6">
          <Row className="align-end">
            <div className="hack col-mattia"> </div>
            <div className="col-mattia child-text">
              <Text>
                <HtmlRender>{project.left_description}</HtmlRender>
              </Text>
            </div>
            <div className="col-mattia-hack"></div>
            <div className="col-mattia noPadding child-text-padding">
              <Text extraMargin>
                <HtmlRender>{project.right_description}</HtmlRender>
              </Text>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Works;
