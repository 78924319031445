import React from "react";
import { Text, ButtonClose } from "./styles";
import { Close } from "@material-ui/icons";
import arrow from "./../../assets/img/arrow-down.svg";
export default function Tabs({
  tabs,
  setActiveTab,
  menuOpen,
  setMenuOpen,
  activeTab,
  scrollToElement,
}) {
  return (
    <>
      <div className="hide-mobile" style={{overflow: "hidden"}}>
        {tabs.map((tab, idx) => (
          <div
            onClick={() => {
              setActiveTab(tab);
              window.scroll(0, 0);
            }}
            style={{
              cursor: "pointer",
              color: activeTab === tab ? "#776E62" : "#776E6266",
            }}
            key={tab}
          >
            <Text
              style={{
                color: activeTab === tab ? "#776E62" : "#776E6266",
              }}
            >
              {tab}
            </Text>
            {idx < tabs.length - 1 && (
              <hr
                style={{
                  marginRight: 15,
                  marginTop: 12,
                  marginBottom: 2,
                  borderBottom: "1px solid rgba(119, 110, 98, 0.4)",
                  borderTop: "none",
                }}
              />
            )}
          </div>
        ))}
      </div>

      <div
        onClick={() => {
          if (window.innerWidth > 750) {
            scrollToElement();
            setTimeout(() => {
              setMenuOpen(true);
            }, 800);
          } else {
            setMenuOpen(true);
          }
        }}
        style={{ position: "relative" }}
        className="show-mobile about-menu-tile-margin"
      >
        <ButtonClose onClick={() => setMenuOpen(false)}>
          <img src={arrow} className="about-menu-button" />
        </ButtonClose>
        <Text style={{ color: "#000" }}>{activeTab}</Text>
        <div
          id="tab-icon-button"
          style={{
            position: "absolute",
            height: "44px",
            width: "30px",
            left: "-30px",
          }}
        />
      </div>
    </>
  );
}
