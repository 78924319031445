import { combineReducers } from "redux";
// Reducers
import tags from "../redux/tags/ducks";
import projects from "../redux/projects/ducks";
import about from "../redux/about/ducks";
import filter from "../redux/Filter/ducks";
import bios from "../redux/bio/ducks";
const appReducer = combineReducers({
  tags,
  projects,
  about,
  filter,
  bios,
});

export default (state, action) => {
  if (action.type === "RESET") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
