import { all, put, takeLatest, call } from 'redux-saga/effects';
import { Types, request, failed, fulfill, success } from '../ducks';
import { getTags } from '../../../../services';

function* loadTags() {
  yield put(request());
  try {
    const { data } = yield call(getTags);
    if (data) {
      yield put(success(data.tags));
    }
  } catch (error) {
    yield put(failed(error));
  } finally {
    yield put(fulfill());
  }
}
export default function* tagsSaga() {
  yield all([takeLatest(Types.TRIGGER, loadTags)]);
}
