import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './storage/store';
import Routes from './routes/index';
import history from './services/history';

function App() {
  const [noScroll, setNoScroll] = useState(false);
  useEffect(() => {
    checkBrowser();
  }, []);
  const checkBrowser = () => {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(' ')) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    if (browserName === 'Safari' && majorVersion >= 14) {
      setNoScroll(true);
    }
    if (browserName === 'Safari' && window.innerWidth <= 600) {
      setNoScroll(true);
    }
  };
  return (
    <>
      {noScroll === false ? (
        <>
          <style
            dangerouslySetInnerHTML={{
              __html: `
              @media screen and (max-width: 600px) {
                body {
                  background-color: rgb(240, 240, 240);
                  font-family: "Myriad", Sans-serif;
                  -webkit-font-smoothing: antialiased;
                  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
                }
              }    

              @media screen and (min-width: 600px) {
                body {
                  background-color: rgb(240, 240, 240);
                  font-family: "Myriad", Sans-serif;
                  -webkit-font-smoothing: antialiased;
                  -webkit-scroll-snap-type: y proximity;
                  scroll-snap-type: y proximity;
                  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
                }
              }    
        `,
            }}
          ></style>
        </>
      ) : (
        <>
          <style
            dangerouslySetInnerHTML={{
              __html: `
                body {
                  background-color: rgb(240, 240, 240);
                  font-family: "Myriad", Sans-serif;
                  -webkit-font-smoothing: antialiased;
                  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
                }
        `,
            }}
          ></style>
        </>
      )}
      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </>
  );
}

export default App;
