import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 333;
  padding-top: 18.9px;
  padding-left: 45px;
  padding-bottom: 0px;
  max-width: 430px;

  @media (max-width: 750px) {
    padding-left: ${(props) => (props.close ? "2.8rem" : "10px")};
  }
`;

export const BG = styled.div`
  position: fixed;
  z-index: 332;
  width: 100vw;
  backgorund
  height: 80px;
  @media (max-width:750px) {
    height: 95px;
    background-color: rgb(240, 240, 240) !important;
  }
  @media (max-width: 350px){
    height: 115px;
    background-color: rgb(240, 240, 240) !important;
  }
`;

export const BGabout = styled.div`
  position: fixed;
  z-index: 332;
  width: 100vw;
  height: 80px;

  @media (max-width: 749px) {
    height: 51px;
  }
`;

export const Container2 = styled.div`
  position: fixed;
  z-index: 333;
  width: max-content;
`;

export const Text = styled.p`
  font-family: "Myriad", Sans-serif;
  line-height: 1.3em;
  margin: 0px;
  font-size: 20px;
  position: relative;
  z-index: 333;
  color: ${(props) => (props.black ? "#000" : "#776e62")};
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  cursor: pointer;
  transition: 600ms;
  text-decoration: none;
  &:hover {
    color: #000;
    transition: 600ms;
  }
`;

export const AboutTitle = styled.p`
  font-family: "Myriad", Sans-serif;
  line-height: 1.3em;
  margin: 0px;
  font-size: 20px;
  position: relative;
  z-index: 333;
  color: ${(props) => (props.black ? "#000" : "#776e62")};
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  cursor: pointer;
  &:hover {
    color: #776e62;
    transition: 400ms;
  }
`;
export const GalleryName = styled.p`
  font-family: Myriad, sans-serif;
  line-height: 1.3em;
  margin-top: -2px;
  font-size: 20px;
  position: relative;
  z-index: 333;
  color: rgb(0, 0, 0);
  letter-spacing: 0.02em;
  cursor: pointer;
  transition: all 600ms ease 0s;
  margin-bottom: 0px;
  color: ${(props) => (props.black ? "#000" : "#776e62")};
  &:hover {
    color: #776e62;
    transition: 600ms;
  }
`;

export const ButtonClose = styled.button`
  position: fixed;
  z-index: 444;
  top: 16px;
  left: 20px;
  margin-top: 4px;
  background: none;
  color: #5a5a5a;
  font-family: "Myriad", Sans-serif;
  font-size: 1.25rem;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: #fff !important;
    transition: 400ms;
  }

  &:focus {
    outline: none !important;
  }
`;
