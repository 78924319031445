import React from "react";
import { Container, Row } from "react-bootstrap";
import { Text } from "./styles";
import HtmlRender from "../../components/HtmlRender";

export default function Timeline({ timeline }) {
  return (
    <Container fluid className=" w-md-100 pb-4">
      <Row style={{ marginBottom: "1rem" }}>
        <div className="hack col-mattia"> </div>
        <div className="col-about-tab">
          {timeline.map((el, idx) => (
            <div key={"timeline " + idx + el.text}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  justifyContent: "space-between",
                  flex: 12,
                }}
              >
                <div style={{ display: "flex", flex: 4 }}>
                  <Text>{el.year}</Text>
                </div>
                <div
                  style={{ display: "flex", flex: 8 }}
                  className="normalize-bios-text-no-margin"
                >
                  {" "}
                  <HtmlRender>{el.text}</HtmlRender>
                </div>
              </div>
              {idx < timeline.length - 1 && (
                <hr
                  style={{
                    marginTop: 12,
                    marginBottom: 2,
                    borderBottom: "1px solid rgba(119, 110, 98, 0.4)",
                    borderTop: "none",
                  }}
                />
              )}
            </div>
          ))}

          <br />
        </div>
      </Row>
    </Container>
  );
}
