import React, { useState, useEffect, useRef } from 'react';
import DATA from '../../data/Data';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { trigger } from '../../storage/redux/tags';
import { success } from '../../storage/redux/Filter/ducks';
// MATERIAL UI
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
// BOOTSTRAP
import { Container, Row, Col, Image } from 'react-bootstrap';
// STYLED COMPONENTS
import {
  Button,
  ButtonClose,
  Title,
  Fixed,
  Left,
  Right,
  FilterText,
  MainTitle,
} from './styles';

function  FilterMenu(props) {
  // STATE HOOKS
  const [img, setImg] = useState();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [fade, setFade] = useState(false);
  const [isTitle, setIsTitle] = useState(false); // Is title available state?!
  const [direction, setDirection] = useState('down'); // Scroll direction state
  const [currentTag, setCurrentTag] = useState(null);
  const [openFilter, setOpenFilter] = useState(false); // Open filter state
  const [currentTagName, setCurrentTagName] = useState('');
  const [prevDirection, setPrevDirection] = useState('down'); // Previous scroll direction state?!
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [displayFilter, enableFilter] = useState(false);

  // REDUX HOOKS
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);
  const { tags } = useSelector((state) => state.tags);
  const { projects, project } = useSelector((state) => state.projects);

  // PROPS
  const myRef = useRef(<div />);
  const leftRef = useRef(<div />);
  var clientY = 0;

  useEffect(() => {
    if (tags && tags.length > 0 && filter.currentFilter === 0) {
      setCurrentTag(tags[0].id);
      setCurrentTagName(tags[0].name);
      dispatch(
        success({ currentFilter: tags[0].id, currentFilterName: tags[0].name })
      );
    } else {
      setCurrentTag(filter.currentFilter);
      setCurrentTagName(filter.currentFilterName);
    }
  }, [tags]);

  
  useEffect(() => {

    if (projects.length > 0) {
      dispatch(trigger());
    }
  }, [dispatch, projects]);

  useEffect(() => {
    for (let index = 0; index < projects.length; index++) {
      const element = projects[index];
    }
    if (currentTag) {
      const filterProjects = projects.filter((project) => {
        const idTags = project.tags.map((tag) => tag.id);
        if (idTags.includes(currentTag)) {
          return true;
        }
        return false;
      });
      setFilteredProjects(filterProjects);
    } else {
      setFilteredProjects(projects);
    }
  }, [currentTag, projects]);

  // WHEN THE PROJECTS LIST COMPONENT GETS RENDERED, THIS ACTIVATES A SCROLL BOTTOM, or at least this is what it should be doing...
  // OBS: THIS IS NOT WORKING?
  const executeScroll = () => {

    const projectButtonsContainer = document.getElementById('project-buttons-container');

    const test = myRef.current;

    projectButtonsContainer.scrollTo(0, test.offsetTop - 44);
  };

  useEffect(() => {
    handleSubmitAPI();

    setTimeout(function () {
      setFade(false);
    }, 600);
    document.body.classList.add('bg-black');
    document.body.classList.add('fadePage');

    //fade Page
    let el = document.querySelector('.fadePage');
    el.classList.add('fadeIn');
    if (isTouchDevice()) {
      window.addEventListener('touchmove', detectTouchMove, {
        passive: true
      });
    } else {
      window.addEventListener('mousewheel', detectMouseWheelDirection);
    }

    return () => {
      document.body.classList.remove('bg-black');
      if (isTouchDevice) {
        window.removeEventListener('touchmove', detectTouchMove);
      } else {
        window.removeEventListener('mousewheel', detectMouseWheelDirection);
      }
    };
  }, []);

  useEffect(() => {
    executeScroll();
  }, [data]);

  const handleSubmitAPI = () => {
    setData(DATA);
  };

  const goto = (page, name) => {
    setFade(true);
    setTimeout(function () {
      props.close();
      props.history.push('/work/' + page);
      props.changePage(name);
    }, 600);
  };

  const renderButtons = () => {
    filteredProjects.sort((a, b) =>
      a.position_id * 1 > b.position_id * 1
        ? 1
        : b.position_id * 1 > a.position_id * 1
        ? -1
        : 0
    );

    return filteredProjects.map((proj, index) => (
      <div
        className="filter-wrapper"
        key={proj.codename}
        ref={proj.codename === project.codename ? myRef : null}
      >
        <Button
          onClick={() => goto(proj.codename, proj.name)}
          isAtive={proj.codename === project.codename}
          onMouseEnter={() => {
            if (proj.main_image) {
              setIsTitle(false);
              setImg(proj.main_image.url);
            } else {
              if (proj.title) {
                setTitle(proj.title);
                setIsTitle(true);
              }
            }
          }}
          onMouseLeave={() => {
            setImg('');
            setTitle('');
            setIsTitle(false);
          }}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '80vw',
            overflowX: 'hidden'
          }}
        >
          {proj.name.length > 30 && window.innerWidth < 500 ? (
            <>
              <p className="big-nav-button-wrapper">{proj.name}</p>
            </>
          ) : (
            proj.name
          )}
        </Button>
      </div>
    ));
  };

  const detectMouseWheelDirection = (e) => {

    let delta = null; 
    let direction = false;

    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }

    // wheelDelta IS DEPRACATED

    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox OBS: FALLBACK NOT WORKING, FEATURE NEVER ADDED BY FIREFOX.
      delta = -e.detail / 2;
    }

    // This detects
    if (delta !== null) {
      direction = delta > 0 ? 'up' : 'down';

      if (direction === 'down') {
        enableFilter(true);
      }
    }

    let off = 0;

    if (document.getElementById('project-buttons-container')) {
      off = document.getElementById('project-buttons-container').scrollTop; // Prop scroll top of this node
    }

    setPrevDirection(direction);

    // This is getting called whenever you scroll past a certain limit
    if (off > 20) {
      setDirection('down');
      setOpenFilter(false); // Ignore this
    }

    return direction;
  };

  const detectTouchMove = (e) => {
    if (e.changedTouches[0].clientY - clientY > 0) {
      setPrevDirection('down');
      setDirection('up');
      enableFilter(true);
    } else {
      setPrevDirection('down');
      setDirection('down');
      enableFilter(true);
    }
    clientY = e.changedTouches[0].clientY;
  };

  const isTouchDevice = () => {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  const renderFilters = () => {
    return tags.map((tag, index) => (
      <>
        {index === tags.length - 1 ? (
          <>
            <Col
              className="p-filter bg-light d-flex align-items-center pt-0 pb-0"
              lg="12"
              style={{
                display: currentTag === tag.id ? 'none !important' : '',
                position: 'relative',
                zIndex: 199,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                paddingLeft: 0
              }}
              key={tag.id}
              onClick={() => {
                setCurrentTag(tag.id);
                setCurrentTagName(tag.name);
                dispatch(
                  success({
                    currentFilter: tag.id,
                    currentFilterName: tag.name
                  })
                );
                setOpenFilter((old) => !old);
              }}
            >
              <FilterText
                style={{
                  display: currentTag === tag.id ? 'none' : ''
                }}
              >
                {tag.name}
              </FilterText>
            </Col>
            <Col
              className="p-filter bg-light d-flex align-items-center pt-0 pb-0"
              lg="12"
              style={{ position: 'relative', zIndex: 199, height: '15px' }}
              key={tag.id + 1}
            >
              <FilterText></FilterText>
            </Col>
          </>
        ) : (
          <Col
            className="p-filter bg-light d-flex align-items-center pt-0 pb-0"
            lg="12"
            style={{
              position: 'relative',
              zIndex: 199,
              paddingLeft: 0
            }}
            key={tag.id}
            onClick={() => {
              setCurrentTag(tag.id);
              setCurrentTagName(tag.name);
              dispatch(
                success({
                  currentFilter: tag.id,
                  currentFilterName: tag.name
                })
              );
              setOpenFilter((old) => !old);
            }}
          >
            <FilterText
              style={{
                display: currentTag === tag.id ? 'none' : ''
              }}
            >
              {tag.name}
            </FilterText>
          </Col>
        )}
      </>
    ));
  };

  return (
    <Container
      fluid
      style={{
        display: props.active ? 'block' : 'none',
        overflowX: 'hidden',
        position: 'relative'
      }}
    >
      <Close
        style={{
          position: 'fixed',
          color: '#5a5a5a',
          top: '20px',
          left: '16px',
          width: '18px',
          cursor: 'pointer',
          zIndex: 333
        }}
        onClick={() => props.close()}
      />

      <Row className={'sticky-navbar'}>
        <Col
          className={
            displayFilter
              ? 'p-filter bg-light d-flex align-items-center sticky-navbar'
              : 'p-filter d-flex align-items-center sticky-navbar-hidden'
          }
          lg="12"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          <Title onClick={() => setOpenFilter((old) => !old)}>
            {currentTagName}
          </Title>

          {/* FILTER MENU OPTIONS (i.e collaborations etc) */}
          <div
            className={
              openFilter
                ? 'filtermenuanimation'
                : openFilter === undefined
                ? 'filtermenuClose'
                : 'filtermenuanimationClose'
            }
          >
            {renderFilters()}
          </div>
        </Col>

        {/* PROJECT NAMES LIST */}
        <div
          className={displayFilter ? 'marginTopButtons' : 'noMarginTopButtons'}
        >
          <Left
            id="project-buttons-container"
            className="p-filter-remove-black-line blank-bottom-space fix-scrollbar"
          >
            {renderButtons()}
            <div className="space-bottom"></div>
          </Left>
        </div>
        <Right>
          <Fixed>
            {isTitle === false ? (
              <>
                <Image
                  className={
                    img != '' ? 'bw preview imgEffect' : 'bw preview imgEffect2'
                  }
                  src={img}
                />
              </>
            ) : (
              <>
                <MainTitle
                  style={{
                    maxHeight: '75%',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    transition: 'opacity .25s ease-in-out'
                  }}
                  className="title"
                >
                  {title}
                </MainTitle>
              </>
            )}
          </Fixed>
        </Right>
      </Row>
    </Container>
  );
}

export default FilterMenu;
